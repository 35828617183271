<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="mt-10 mb-10 ml-10">
          <form enctype="multipart/form-data">
            <v-card-title class="cyan darken-1">
              <span class="white--text">Actualizar Equipos</span>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-row>
              <v-col cols="12" md="3"></v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="name" label="Nombre" :rules="nameRules"></v-text-field>
                <v-checkbox @change="checked" v-model="chk_promotion" label="Promoción"></v-checkbox>
                <div id="txt_price">
                  <v-text-field v-model="price" label="Precio *" type="number" :rules="priceRules"></v-text-field>
                  <v-text-field :rules="priceRules" type="number" v-model="previous_price" label="Precio Anterior *"></v-text-field>
                </div>
                <v-file-input v-model="image" accept="image/png, image/jpeg, image/bmp" placeholder="Inserte una imagen"
                  prepend-icon="mdi-camera" label="Imagen de equipo *">
                </v-file-input>
                <v-textarea v-model="description" label="Descripción *" :rules="descriptionRules"></v-textarea>
              </v-col>
              <v-col cols="12" md="3"></v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row>
              <v-col cols="12" md="8"></v-col>
              <v-col cols="12" md="4">
                <div class="text-right mb-2 mt-4">
                  <v-btn color="primary" class="mr-4" outlined @click="cancel">
                    <v-icon>mdi-close-circle</v-icon>
                    <span class="ml-1">Cancelar</span>
                  </v-btn>
                  <v-btn color="primary" class="mr-4" outlined @click="update">
                    <v-icon>mdi-sync-circle</v-icon><span class="ml-1">Actualizar</span>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    name: "",
    chk_promotion: false,
    description: "",
    price: 0,
    previous_price: 0,
    image: "",
    nameRules: [
      v => !!v || 'El nombre es requerido',
      v => v.length <= 25 || "El campo nombre no debe exceder de 25 caracteres",
    ],
    priceRules: [
      v => !!v || 'El precio es requerido',
      v => v >= 0 || 'El valor precio debe ser mayor o igual a cero',
    ],
    imageRules: [
      v => !!v || 'La imagen es requerida',
    ],
    descriptionRules: [
      (v) =>
        (v.length <= 500) || "El campo no debe exceder de 500 caracteres",
    ],
  }),
  mounted() {
    this.chargeData();
  },
  methods: {
    cancel() {
      setTimeout(() => this.$router.push({ name: "view-equipments" }), 10);
    },

    checked() {
      this.chk_promotion = !this.chk_promotion;
      document.getElementById("txt_price").style.display = this.chk_promotion
        ? "none"
        : "block";
    },
    chargeData() {
      console.log("cargando datos");
      let request = {
        id: this.$route.params.id,
      };
      axios
        .post("/equipment", request)
        .then((response) => {
          console.log(response.data);
          this.name = response.data.name;
          if (
            response.data.price.toString() == "0" &&
            response.data.previous_price.toString() == "0"
          ) {
            this.chk_promotion = true;
            document.getElementById("txt_price").style.display = "none";
          }
          this.price = response.data.price.toString();
          this.previous_price =
            response.data.previous_price.toString() == !""
              ? 0
              : response.data.previous_price.toString();
          this.image = response.data.image;
          this.description = response.data.description;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los datos del equipo"
          );
        });
    },
    update() {
      console.log("actualizando equipo");
      let formData = new FormData();
      formData.append("name", this.name);
      if (this.chk_promotion) {
        formData.append("price", 0);
        formData.append("previous_price", 0);
      } else {
        formData.append("price", this.price);
        formData.append("previous_price", this.previous_price);
      }
      formData.append("image", this.image);
      formData.append("description", this.description);
      formData.append("id", this.$route.params.id);
      axios
        .post("/equipment-update", formData)
        .then((response) => {
          console.log(response.data);
          this.displayNotification("success", "Éxito", "Equipo actualizado correctamente");
          console.log("equipo ACTUALIZADO");
          setTimeout(
            () => this.$router.push({ name: "view-equipments" }),
            4000
          );
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error en la actualización de equipo"
          );
          setTimeout(
            () => this.$router.push({ name: "view-equipments" }),
            4000
          );
        });
    },
  },
};
</script>